import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout';
import Seo from '../components/seo/seo';

const IndexPage = () => {
  return (
    <Layout page="home">
      <Seo title="Főoldal" />
      <div className="hero container-fluid">
        <div className="hero-images">
          <img src="/images/landing1.jpg" alt="Landing image" />
          <img src="/images/landing3.jpg" alt="Landing image" />
          <img src="/images/landing2.jpg" alt="Landing image" />
        </div>
      </div>
      <div className="intro container-fluid">
        <div className="centered">
          <h1 className="page-title"><Trans>welcome</Trans></h1>
          <p><Trans>intro1</Trans></p>
          <p><Trans>intro2</Trans></p>
          <p><Trans>intro3</Trans></p>
          <p><Trans>intro4</Trans></p>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;